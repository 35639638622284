import axios from "axios";
import { getApiDomain } from "../../config";

const API_URL_CRUD = (getApiDomain());

export default function GetEmail(userid: string) {
    return axios.post(API_URL_CRUD + "/auth/getemail", {
        userid
      });
    }

    /**
     * node backend 8082
     * 
     * let Session = require("supertokens-node/recipe/session").default;
let { getUserById } = require("supertokens-node/recipe/emailpassword");
app.post("/someAPI", Session.verifySession(), async (req, res) => {
  let session = req.session;
  let userId = req.session.getUserId();
  let user = getUserById(userId);
  let email = user.email;
});
     * 
     * 
     */