import axios from "axios";
import { getQrcodeApiDomain } from "../../config";

const API_URL_CRUD = (getQrcodeApiDomain()+ "/api/");

export default function SendEmail(email: string, link: string) {
    return axios.post(API_URL_CRUD + "sendemail", {
        email,
        link
      });
     
    }
