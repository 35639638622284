import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";

import { ContentBlockProps } from "../../common/ContentBlockCss/types";
import { SvgIcon } from "../../common/SvgIcon";
import {
  ContentSection,
  Content,
  ContentWrapper,
  ServiceWrapper,
  MinTitle,
  MinPara,
  StyledRow,
  Span,
  Large,
} from "../../common/ContentBlockCss/styles";
import { Button } from "../../common/Button";
import CodeScan from "../CodeScan";
import { useState } from "react";
import { QrReader } from "react-qr-reader";
import {  useEffect } from "react";
import readqr from "../../services/QrcodeScan";
import grantaward from "../../services/GrantAward"
import getuser from '../../common/SessionUser'
//import Session from 'supertokens-web-js/recipe/session';


const PartnerContentBlock = ({
  title,
  content,
  section,
  t,
  id,
  direction,
  result,
  icon,
  error
}: ContentBlockProps) => {

  
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [facingMode, setFacingMode] = useState("environment");

 /**  async function getuser() {
    if (await Session.doesSessionExist()) {
          let loggedname = await Session.getUserId();
          console.log("email is", loggedname);
    }
}*/

  const handleScan = (result: any, error: any) => {
    if (!!result) {
      setData(result.text);
      setStartScan(false);
      setLoadingScan(false);
     // senddata();
     if (result.text.length != 10)
        window.alert("the Qrcode is invalid handle scan function");
      
    }
   else if (!!error) {
      console.info(error);
    }
  }

  async function senddata() {
    let res;
  //let name = "amro";
  //let qrcode = "C8E265328CB";
  let userid = getuser();
  console.log("user is is ", userid)
   res = await readqr(name, data);
 // if (JSON.stringify(res.status) == "200") {
    if (res.status == 200) {
        if (window.confirm(`is recycle waste had been filled with required procedures`))
        { if(await grantaward(name, data))
            window.alert("operation done successufly")
            else 
            window.alert("it had been used or can not be granted")
        }
        else {}
    }
    else 
    window.alert("the Qrcode is invalid");

 //   if (window.confirm(`is the option ${qrcode} confirmed`))
 // window.alert("now you need drop down popup menu too approve the rerward")
  }
  
 useEffect( () => {
    senddata();
    }, [data])

return (
    <>
    <ContentSection>   
      <StyledRow
        justify="space-between"
        align="middle"
        id={id}
        direction={direction}
      >
        <Col lg={22} md={22} sm={22} xs={24}>
          <ContentWrapper>
            <h3>{t(title)}</h3>
            <Content>{t(content)}</Content>
            <Button
                onClick={() => {  
                  setData("");
                  setStartScan(!startScan);
                  setLoadingScan(!loadingScan);
                } }
               name="Scan now">{startScan ? t("Stop scan") : t("Scan now")}
              </Button>
          </ContentWrapper>
        </Col>
      </StyledRow>
      <div>
     <p></p>
     </div>
      {startScan && (
  <><select onChange={(e) => setFacingMode(e.target.value)}>
        <option value={"environment"}>Back Camera</option>
        <option value={"user"}>Front Camera</option>
      </select>
      <div>
     <p></p>
     </div>
      <QrReader
          constraints={{
            facingMode,
          }}
          videoStyle={{width: 300, height: 300, border: 'solid', borderWidth: '4px', borderColor: data.length === 0 ? 'green' : 'blue', }}
          scanDelay={500}
          onResult={handleScan}
          /></>
        )}
    </ContentSection>
    {loadingScan && <p>Loading</p>}
    {data !== "" && <p>{data}</p>}
    </>
     
  );


};

export default withTranslation()(PartnerContentBlock);
