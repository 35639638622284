import Session from 'supertokens-web-js/recipe/session';

async function getuser() {
    if (await Session.doesSessionExist()) {
          let userId  = await Session.getUserId();
          //let loggedname = accessTokenPayload.name;
          console.log("name is", userId);
          return userId;
    }
    else 
    return "anymonus";
}
export default  getuser