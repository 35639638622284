import { useState } from "react";
import { notification } from "antd";
import { signIn, signUp } from "supertokens-web-js/recipe/emailpassword";
import  {LoadingSpinner}  from "../../common/LoadingSpinner"

interface IValues {
  name: string;
  email: string;
  password: string;
}

const initialValues: IValues = {
  name: "",
  email: "",
  password: "",
};

export const useFormsignup = () => {
  const [loading, setLoading]   = useState(false);
  const [formState, setFormState] = useState<{
    values: IValues;
    errors: IValues;
  }>({
    values: { ...initialValues },
    errors: { ...initialValues },
  });



  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const values = formState.values;
    //const errors = validate(values);
   // setFormState((prevState) => ({ ...prevState, errors }));
   setLoading(true);
if (values.name === "login")    

{
  
      try {
      
          let signres = await signIn ({
            formFields: [{
              id: "email",
              value: values.email
          },
              {
              id: "password",
              value: values.password
          }]
      })
          if (signres.status === "FIELD_ERROR") {
            signres.formFields.forEach(formField => {
              if (formField.id === "email") {
            notification["error"]({
              message: "Error",
              description: formField.error,
            })
          } 
          })
        } else if (signres.status === "WRONG_CREDENTIALS_ERROR") {
          notification["error"]({
            message: "Error",
            description: "Email password combination is incorrect",
          });  
        //  window.alert("Email password combination is incorrect");
        } else if (signres.status === "SIGN_IN_NOT_ALLOWED") {
            notification["error"]({
              message: "Error",
              description: signres.reason,
            });
         //   window.alert(`You are not allowed to sign in ${signres.reason}`);
        } 
       else {
        event.target.reset();
        setFormState(() => ({
              values: { ...initialValues },
              errors: { ...initialValues },
            }));
            setTimeout(() => {
              window.location.href = "/"
              }, 2000);
            }
   
      } catch (err: any) {
        if (err.isSuperTokensGeneralError === true) {
        notification["error"]({
          message: "Error",
          description: "Failed to submit form. Please try again later.",
        }); }
        else {
          window.alert("Oops! Something went wrong.");
      }
      }
      event.target.reset();
      setLoading(false);
      setFormState(() => ({
        values: { ...initialValues },
        errors: { ...initialValues },
      }));

     }
 
else {  
  
  try {
        let signupres = await signUp({
          formFields: [{
            id: "email",
            value: values.email
        },
            {
            id: "password",
            value: values.password
        }] 
    })
        if (signupres.status === "FIELD_ERROR") {
          signupres.formFields.forEach(formField => {
            if (formField.id === "email") {
          notification["error"]({
            message: "Error",
            description: formField.error,
          })
        } else if (formField.id === "password") {
          notification["error"]({
            message: "Error",
            description: formField.error,
          });   }
        })
      }else if (signupres.status === "SIGN_UP_NOT_ALLOWED") {
        notification["error"]({
          message: "Error",
          description: signupres.reason,
        }); 
    } else {
      event.target.reset();
          setFormState(() => ({
            values: { ...initialValues },
            errors: { ...initialValues },
          }));
          notification["success"]({
            message: "Success",
            description: "Registeration successfull !",
          });
          
      setTimeout(() => {
      window.location.href = "/user"
      }, 50);
      
  }
 
      
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
      notification["error"]({
        message: "Error",
        description: "Failed to submit form. Please try again later.",
      }); }
      else {
        window.alert("Oops! Something went wrong.");
    }
    
    }
  }
  setLoading(false);
  event.target.reset();
  setFormState(() => ({
    values: { ...initialValues },
    errors: { ...initialValues },
  }));
  };


  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.persist();
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));

      
  };
  const Spinner = () =>{
   
    if (loading)
      {return <LoadingSpinner/>;}
        
      }
      
      Spinner();
  return {
    
    handleChange,
    handleSubmit,
    values: formState.values,
    errors: formState.errors,
  };

};

