import axios, { AxiosResponse } from "axios";
import { getQrcodeApiDomain } from "../../config";

const API_URL_CRUD = (getQrcodeApiDomain()+ "/api/");


export default function getaward(name: string,): Promise<AxiosResponse<{awardcounter: number}>> {
    return axios.put(API_URL_CRUD + "award", {
      name
      });
     
    }